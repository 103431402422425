import ApiUrl from "./ApiUrl";

const MemosData = new function() {
	const this_ = this;
	const localStorageKey_ = "memos-data";
	//window.localStorage.removeItem(localStorageKey_);
	let memos_ = JSON.parse(window.localStorage.getItem(localStorageKey_)) || [];
	let callbacks_ = [];

	// subscribe
	this_.subscribe = callback => {callbacks_.push(callback);};
	this_.unsubscribe = callback => {callbacks_.splice(callbacks_.indexOf(callback), 1);}

	// get
	this_.getMemos = () => memos_;
	this_.getMemo = id => memos_.find(memo => memo.u === id);

	// save
	function saveMemos() { window.localStorage.setItem(localStorageKey_, JSON.stringify(memos_)); }
	this_.saveMemo = (memo, title, body) => {
		memo.t = title;
		memo.b = body;
		if (!this_.getMemo(memo.u)) memos_.push(memo);
		saveMemos();
	};

	// new
	this_.newMemo = () => {
		return {
			u:Math.round((Date.now()-1646166613948)*0.001),
			t:"",
			b:""
		};
	};

	// sync
	this_.syncMemos = (sessionGuid, onSynced) => {
		fetch(ApiUrl+"memos.sync.php", {method:"POST", mode: 'cors', body:JSON.stringify({g:sessionGuid, m:memos_})})
			.then(response => response.json())
			.then(response => {
				if (response.m)
				{
					memos_ = response.m;
					saveMemos();
					callbacks_.forEach(callback => {callback();});
				}
				onSynced(response.e);
			})
			.catch(exception => { onSynced(exception); });
	};
};

export default MemosData;