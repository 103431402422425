import styled from "styled-components";

const FormWrapper = styled.form`
	& > label {
		display:block;
		& > span { display: block; }
	}
	
	display: flex;
	flex-flow:column nowrap;
	height:100%;
	& > label {
		& > input,
		& > textarea {
			width:100%;
			box-sizing: border-box;
		}
		
		&.Body {
			flex:1;
			display:flex;
			flex-flow:column nowrap;
			& > textarea { flex:1; }
		}
	}
`;

export default FormWrapper;