import React, {Component} from "react";
import styled from "styled-components";
import LoginModule from "./LoginModule";
import MemosData from "../Data/MemosData";

const SyncWrapper = styled.div`
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:var(--bg1);
	padding:1em;
`;

class SyncButton extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isSyncing:false,
			error:false
		};
	}

	doSync(sessionGuid) {
		this.setState({isSyncing:true});
		MemosData.syncMemos(sessionGuid, error => {
			this.setState({isSyncing:false, error:error});
		});
	}

	render() {

		// input
		const {isSyncing, error} = this.state;
		const {sessionGuid} = this.props;

		// display
		return (
			<>
				<button onClick={()=>{ if (sessionGuid) this.doSync(sessionGuid); this.setState({isSyncing:true}); }}>Sync</button>
				{
					!isSyncing && !error ? false :
					<SyncWrapper onClick={!error?undefined:()=>{this.setState({error:false});}}>
						{
							!sessionGuid ? <LoginModule onLogin={newSessionGuid => { this.doSync(newSessionGuid); }} />
							:(error||"Syncing...")
						}
					</SyncWrapper>
				}
			</>
		)
	}
}

export default SyncButton;