import React, {Component} from "react";
import MemosData from "../Data/MemosData";
import {Link} from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
	& > a {
		display: block;
		border-bottom:solid 1px var(--bg2);
		padding:1em;
		& > div {
			&:first-child { font-weight: bold; }
			&:nth-child(2) { 
				font-size:0.8em; 
				font-style: italic;
				margin-top:0.5em;
			}
		}
	}
`;

class MemosPage extends Component {

	constructor(props) {
		super(props);
		this.state = {memos:MemosData.getMemos()};
		this.updateMemos = this.updateMemos.bind(this);
	}

	updateMemos() { this.setState({memos:MemosData.getMemos()}); }
	componentDidMount() { MemosData.subscribe(this.updateMemos); }
	componentWillUnmount() { MemosData.unsubscribe(this.updateMemos); }

	render() {

		// input
		const {memos} = this.state;

		// display
		return (
			<Wrapper>
				<Link to="/memo/0">New Memo</Link>
				{
					memos.map(memo =>
						<Link key={memo.u} to={`/memo/${memo.u}`}>
							<div>{memo.t}</div>
							<div>{memo.b}</div>
						</Link>
					)
				}
			</Wrapper>
		)
	}
}

export default MemosPage;