import React, {Component} from "react";
import MemosData from "../Data/MemosData";
import {useParams} from "react-router-dom";
import FormWrapper from "../Components/FormWrapper";

function MemoPage({sessionGuid})
{
	const { memoId } = useParams();
	return ( <MemoPageV6BS sessionGuid={sessionGuid} memoId={memoId-0} />);
}

class MemoPageV6BS extends Component {

	constructor(props) {
		super(props);
		this.state = {memo:MemosData.getMemo(props.memoId) || MemosData.newMemo()};
	}

	render() {

		// input
		const {memo} = this.state;

		// display
		return (
			<FormWrapper
				className="Memo"
				onSubmit={event => {
					event.preventDefault();
					MemosData.saveMemo(memo, event.target.t.value, event.target.b.value);
				}}
			>
				<label>
					<input placeholder="Title" type="text" name="t" defaultValue={memo.t} autoComplete="off" />
				</label>
				<label className="Body">
					<textarea placeholder="Body" name="b" defaultValue={memo.b} />
				</label>
				<input type="submit" value="Save" />
			</FormWrapper>
		)
	}
}

export default MemoPage;