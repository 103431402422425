import React, {Component} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import MemosPage from "./Pages/MemosPage";
import SessionData from "./Data/SessionData";
import MemoPage from "./Pages/MemoPage";
import HeaderModule from "./Modules/HeaderModule";
import styled from "styled-components";

const Wrapper = styled.div`
	height:100%;
	display: flex;
	flex-flow:column nowrap;
	justify-content: center;
`;

const ContentWrapper = styled.div`
	flex:1;
`;

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			sessionGuid:SessionData.initialize(sessionGuid => {
				this.setState({sessionGuid:sessionGuid});
			})
		};
	}

	render() {

		// input
		const {sessionGuid} = this.state;

		// display
		return (
			<Wrapper>
				<Router>
					<HeaderModule sessionGuid={sessionGuid}/>
					<ContentWrapper>
						<Routes>
							<Route path="/" element={<MemosPage sessionGuid={sessionGuid} />} />
							<Route path="/memo/:memoId" element={<MemoPage sessionGuid={sessionGuid} />} />
						</Routes>
					</ContentWrapper>
				</Router>
			</Wrapper>
		)
	}
}

export default App;