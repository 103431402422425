import React from 'react';
import {Link} from "react-router-dom";
import SessionData from "../Data/SessionData";
import styled from "styled-components";
import SyncButton from "./SyncButton";

const Wrapper = styled.div`
	display:flex;
	flex-flow:row nowrap;
	justify-content: space-between;
	border-bottom:solid 1px #333;
	
	color:var(--fg2);
	background: var(--bg2);
`;

function HeaderModule({sessionGuid}) {

	// display
	return (
		<Wrapper>
			<Link to="/">Home</Link>
			<button onClick={()=>{SessionData.logout();}} style={sessionGuid?undefined:{display:"none"}}>Logout</button>
			<SyncButton sessionGuid={sessionGuid} />
		</Wrapper>
	);
}

export default HeaderModule;