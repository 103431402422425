import React from 'react';
import SessionData from "../Data/SessionData";
import FormWrapper from "../Components/FormWrapper";

function LoginModule({onLogin}) {

	// display
	return (
		<FormWrapper onSubmit={event => {
			event.preventDefault();
			SessionData.login(event.target.u.value, event.target.p.value, onLogin);
		}}>
			<label>
				<input placeholder="Username" type="username" name="u" />
			</label>
			<label>
				<input placeholder="Password" type="password" name="p" />
			</label>
			<div><input type="submit" value="Login" /></div>
		</FormWrapper>
	);
}

export default LoginModule;