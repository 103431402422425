import MemosData from "./MemosData";
import ApiUrl from "./ApiUrl";

const SessionData = new function() {
	const this_ = this;
	const localStorageKey_ = "memo-g";
	let sessionGuid_ = window.localStorage.getItem(localStorageKey_);
	let onChange_ = ()=>{};

	// get
	this_.initialize = (onChange) => {
		onChange_ = onChange;
		return sessionGuid_;
	};

	// login
	this_.login = (username, password, onLogin) => {
		fetch(ApiUrl+"login.php", {method:"POST", mode: 'cors', body:JSON.stringify({u:username, p:password})})
			.then(response => response.json())
			.then(response => {

				if (response.g)
				{
					window.localStorage.setItem(localStorageKey_, sessionGuid_ = response.g);
					onChange_(sessionGuid_);
					if (onLogin) onLogin(sessionGuid_);
				}

			});
	};

	// logout
	this_.logout = () => {
		if (sessionGuid_) fetch(ApiUrl+"logout.php", {method:"POST", mode: 'cors', body:JSON.stringify({g:sessionGuid_})});

		//MemosData.clearMemos();
		sessionGuid_ = undefined;
		window.localStorage.removeItem(localStorageKey_);
		onChange_(sessionGuid_);
	};
};

export default SessionData;